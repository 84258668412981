<template>
  <section class="bg-dark py-5 text-light footer-widget">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-xl-4 text-center text-md-left">
            <div class="mt-4">
                <a  class="d-block">
                  <img class="lazyload" :src="footLogoUrl"  height="44">
                </a>
                <div class="my-3">
                  {{desc}}
                </div>
                <div class="d-inline-block d-md-block mb-4">
                    <form class="form-inline" method="POST">
                        <div class="form-group mb-0">
                            <input type="email" class="form-control" :placeholder="$t('nin-de-dian-zi-you-jian-di-zhi')" name="email" required>
                        </div>
                        <button type="submit" class="btn btn-primary">
                           {{ $t('ding-yue') }} </button>
                    </form>
                </div>
                <div class="w-300px mw-100 mx-auto mx-md-0">
                  <a  target="_blank" class="d-inline-block mr-3 ml-0">
                    <img :src="playUrl" class="mx-100 h-40px">
                  </a>
                  <a  target="_blank" class="d-inline-block">
                    <img :src="appUrl" class="mx-100 h-40px">
                  </a>
                </div>
            </div>
        </div>
        <div class="col-lg-3 ml-xl-auto col-md-4 mr-0">
            <div class="text-center text-md-left mt-4">
                <p class="fs-13 text-uppercase fw-600 border-bottom border-gray-900 pb-2 mb-4">
                    {{ $t('lian-xi-fang-shi') }} </p>
              <template v-if="content.length > 0">
                <div v-html="content"></div>
              </template>
              <template v-else>
                <ul class="list-unstyled">

                  <li class="mb-2">
                    <span class="d-block opacity-30">{{ $t('di-zhi-1') }}</span>
                    <span class="d-block opacity-70">{{address}}</span>
                  </li>
                  <li class="mb-2">
                    <span class="d-block opacity-30">{{ $t('dian-hua-1') }}</span>
                    <span class="d-block opacity-70">400-612-4007</span>
                  </li>
                  <li class="mb-2">
                    <span class="d-block opacity-30">{{ $t('dian-zi-you-jian-0') }}</span>
                    <span class="d-block opacity-70">
                            <a :href="`mailto:${email}`" class="text-reset">{{email}}</a>
                        </span>
                  </li>
                </ul>
              </template>
            </div>
        </div>
        <div class="col-lg-2 col-md-4">
            <div class="text-center text-md-left mt-4">
                <p class="fs-13 text-uppercase fw-600 border-bottom border-gray-900 pb-2 mb-4">
                    {{ $t('kuai-su-lian-jie') }} </p>
                <ul class="list-unstyled">
                  <li class="mb-2" v-for="(item, i) in linkList" :key="i" @click="toPath(item.path, true)">
                    <a  class="opacity-50 hov-opacity-100 text-reset">
                        {{ item.name }}
                    </a>
                  </li>
                </ul>
            </div>
        </div>

        <div class="col-md-4 col-lg-2">
            <div class="text-center text-md-left mt-4">
                <p class="fs-13 text-uppercase fw-600 border-bottom border-gray-900 pb-2 mb-4">
                    {{ $t('wo-de-zhang-hu') }} </p>
                <ul class="list-unstyled">
                  <li class="mb-2" v-if="isLogin" @click="toLogout">
                    <a class="opacity-50 hov-opacity-100 text-reset" >
                      {{ $t('tui-chu') }} </a>
                  </li>
                  <li class="mb-2" v-else  @click="toPath('login')">
                    <a class="opacity-50 hov-opacity-100 text-reset" >
                      {{ $t('deng-lu') }} </a>
                  </li>
                  <li class="mb-2" @click="toPath('purchase_history')">
                    <a class="opacity-50 hov-opacity-100 text-reset" >
                      {{ $t('ding-dan-li-shi') }} </a>
                  </li>
                  <li class="mb-2" @click="toPath('wishlist')">
                    <a class="opacity-50 hov-opacity-100 text-reset" >
                      {{ $t('wo-de-shou-cang') }} </a>
                  </li>
                  <li class="mb-2" @click="toPath('trackOrder')">
                    <a class="opacity-50 hov-opacity-100 text-reset">
                       {{ $t('gen-zong-ding-dan-1') }} </a>
                  </li>
                  <li class="mb-2" @click="toPath('affiliate')">
                    <a class="opacity-50 hov-opacity-100 text-light" >{{ $t('cheng-wei-hui-yuan-he-zuo-huo-ban') }}</a>
                  </li>
                </ul>
            </div>
            <div class="text-center text-md-left mt-4">
                <p class="fs-13 text-uppercase fw-600 border-bottom border-gray-900 pb-2 mb-4">
                  {{ $t('zuo-mai-jia') }} </p>
                <a  class="btn btn-primary btn-sm shadow-md" @click="toPath('shopCreate', true)">
                  {{ $t('xian-zai-shen-qing') }} </a>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { removeToken, removeUserType } from '@/utils/auth'
import { getArticleLists } from '@/api/index'

export default {
  name: 'tabbar',
  props:{

  },
  data() {
    return {
      desc: '',
      // footLogoUrl: require('../../assets/imgs/foot-logo.png'),
      footLogoUrl1: require('../../assets/imgs/foot-logo.png'),
      playUrl: require('../../assets/imgs/play.png'),
      appUrl: require('../../assets/imgs/app.png'),
      email: 'tiktokspecialsmall@outalook.com',
      content: ''
    }
  },
  computed: {
    isLogin() {
      return this.$store.state.isLogin
    },
    address(){
      return this.$store.state.address
    },
    footLogoUrl() {
      return this.$store.state.logo.length > 0
          ? "/upload/tmp/" + this.$store.state.logo
          : this.footLogoUrl1;
    },
    linkList() {
      return [
        {
          name: this.$t('kai-dian-ru-zhu-zheng-ce'),
          path: 'sellerPolicy'
        },
        {
          name: this.$t('tui-huo-zheng-ce'),
          path: 'returnPolicy'
        },
        {
          name: this.$t('zhi-chi-zheng-ce'),
          path: 'supportPolicy'
        },
        {
          name: this.$t('yin-si-ce-lve'),
          path: 'privacyPolicy'
        },
        {
          name: this.$t('tiao-kuan-tiao-jian'),
          path: 'terms'
        },
        {
          name: this.$t('fa-huo-gui-ze'),
          path: 'shippingRule'
        },
      ]
    }
  },
  watch: {
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '8')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path, noAuth) {
      if (path && (this.isLogin || noAuth)) {
        this.$router.push({
          name: path
        })
      } else {
        if (!this.isLogin) {
          this.$router.push({
            name: 'login'
          })
        }
      }
    },
    toLogout() {
      removeToken()
      removeUserType()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'index'
      })
    }
  }
}
</script>